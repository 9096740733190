import React from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import FormActions from '../Input/FormActions';
import invoke from '../../lambda';
import SpanResponse from './SpanResponse';

const Span = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      height: form.values.height || '302',
      width: form.values.width || '',
      chosenType: form.values.chosenType || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'P302',
        type: 'span',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
    validate: ({ height }) => {
      const errors = {};

      if (!height) {
        errors.height = 'Wymagana wartość';
      } else if (height < 60) {
        errors.height = 'Minimalna wysokość to 60mm.';
      } else if (height > 302) {
        errors.height = 'Maksymalna wysokość to 302mm.';
      }

      return errors;
    },
  });
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`chosenType__${form.id}`}>Typ płyty</label>
          <select
            className="form-control"
            id={`chosenType__${form.id}`}
            name="chosenType"
            placeholder="Wybierz"
            onChange={formik.handleChange}
            value={formik.values.chosenType}
            required
          >
            <option value="" disabled>Wybierz</option>
            <option value={1}>302 mm</option>
            <option value={2}>Niestandardowa</option>
          </select>
        </div>
        {formik.values.chosenType && formik.values.chosenType === '2' && (
          <div className="form-group">
            <label htmlFor={`height__${form.id}`}>Wysokość niestandardowa (mm)</label>
            <input
              type="number"
              className="form-control"
              placeholder="Wpisz"
              id={`height__${form.id}`}
              name="height"
              value={formik.values.height}
              onChange={formik.handleChange}
              autoComplete="off"
              required
            />
            <span className="error">{formik.errors.height && formik.errors.height}</span>
          </div>
        )}
        <div className="form-group">
          <label htmlFor={`width__${form.id}`}>Szerokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`width__${form.id}`}
            name="width"
            value={formik.values.width}
            onChange={formik.handleChange}
            autoComplete="off"
            required
          />
          <span className="error">{formik.errors.width && formik.errors.width}</span>
        </div>
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <SpanResponse response={form.response} isPdf={false} />}
    </form>
  );
};

Span.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Span;
