/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { fetchProductionMaterialsMulti } from 'reducers';
import {
  FaChevronDown, FaChevronUp, FaRegDotCircle, FaPlus, FaMinus,
} from 'react-icons/fa';

import {
  AmountColumnHeader,
  CustomLpColumnHeader,
  MaterialColumnHeader,
  NameColumnHeader,
} from 'Pages/ProductionMaterials/components';
import GenerateXLS from './xls';
import animatedGate from './OK2.gif';

const Container = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const resolveToolName = (t) => {
  switch (t) {
    case 'angles': return 'Kąty';
    case 'saw': return 'Piła';
    case 'laser': return 'Laser';
    case 'studding': return 'Nabijanie';
    case 'filling': return 'Wypełnienie';
    case 'welding': return 'Spawanie';
    case 'bending': return 'Walcarki';
    case 'kazimierz': return 'Kazimierz';
    case 'gallantry': return 'Galanteria';
    case 'gallantry1': return 'Galanteria - Rozcinarka';
    case 'gallantry2': return 'Galanteria - Zaginarka';
    case 'railing': return 'Sztachety';
    case 'spacing': return 'Rozstaw';
    case 'plank': return 'Plank';
    case 'spacingLeft': return 'Rozstaw - lewe';
    case 'spacingRight': return 'Rozstaw - prawe';
    case 'mailboxInfo': return 'Informacja nt. skrzynki na listy';
    case 'railHoles': return 'Otwory pod sztachetę';
    case 'palisadeHoles': return 'Otwory pod palisadę';
    case 'palisadeHolesAdd': return 'Otwory pod palisadę w dodatkowym poziomie';
    case 'channelHoles': return 'Otwory w ceowniku montażowym';
    case 'rodHoles': return 'Zacinki w kątowniku zakrywającym';
    case 'info': return 'Informacje';
    case 'infoAboveMachine': return 'Informacje nad automatem';
    case 'infoUnderMachine': return 'Informacje pod automatem';
    case 'gearRail': return 'Magazyn - Zbrojenie';
    case 'mountingStrip': return 'Otwory montażowe w listwie montażowej';
    case 'storage': return 'Magazyn - Osprzęt';
    case 'storageWrapper': return 'Magazyn - Owijarka';
    case 'buffer': return 'Bufor';

    default: return t;
  }
};

const resolveSectionHeader = (t) => {
  switch (t) {
    case 'angles': return 'KĄTY - MATERIAŁY';
    case 'saw': return 'PIŁA - MATERIAŁY DO WYCIĘCIA';
    case 'laser': return 'LASER - MATERIAŁY DO WYPALENIA';
    case 'studding': return 'NABIJANIE - MATERIAŁY';
    case 'filling': return 'WYPEŁNIENIE - MATERIAŁY';
    case 'welding': return 'SPAWANIE - MATERIAŁY';
    case 'kazimierz': return 'KAZIMIERZ - MATERIAŁY';
    case 'gallantry': return 'GALANTERIA - MATERIAŁY';
    case 'gallantry1': return 'GALANTERIA - ROZCINARKA';
    case 'gallantry2': return 'GALANTERIA - ZAGINARKA';
    case 'railing': return 'SZTACHETY - MATERIAŁY';
    case 'plank': return 'PLANK - MATERIAŁY';
    case 'bending': return 'WALCARKI';
    case 'storageWrapper': return 'MAGAZYN - OWIJARKA';
    case 'storage': return 'MAGAZYN - OSPRZĘT';
    case 'mountingStrip': return 'OTWORY MONTAŻOWE W LISTWIE MONTAŻOWEJ';
    case 'gearRail': return 'MAGAZYN - ZBROJENIE';
    case 'buffer': return 'BUFOR';

    default: return t;
  }
};

const resolveItemName = (t) => {
  switch (t) {
    case 'akcesoria': return 'Akcesoria';
    case 'przesla': return 'Przęsło';
    case 'uchylna': return 'Brama uchylna';
    case 'furtki': return 'Furtka';
    case 'lamana': return 'Brama łamana';
    case 'samonosna': return 'Brama samonośna';
    case 'slupek_funkcjonalny': return 'Słupek funkcjonalny';
    case 'carport': return 'Carport';
    case 'carport_rozbudowa': return 'Carport rozbudowa';
    case 'system_balastowy': return 'System balastowy';
    case 'wiatrownica': return 'Wiatrownica';
    case 'slupy_fotowoltaika': return 'Słupy fotowoltaika';
    case 'stoly_fotowoltaika': return 'Stoły fotowoltaika';
    default: return t;
  }
};
const resolveHolesName = (t) => {
  switch (t.toLowerCase()) {
    case 'railholes': return 'OTWORY POD SZTACHETE';
    case 'palisadeholes': return 'OTWORY POD PALISADĘ';
    case 'channelholes': return 'OTWORY W CEOWNIKU MONTAŻOWYM';
    case 'rodholes': return 'ZACINKI W KĄTOWNIKU ZAKRYWAJĄCYM';
    case 'palisadeholesadd': return 'OTWORY POD PALISADĘ W DODATKOWYM POZIOMIE';
    case 'furtki': return 'Furtka';
    default: return t;
  }
};

const sortAndFilterToolsArray = (unsortedToolsArray) => {
  const orderedArray = [
    'angles',
    'laser',
    'saw',
    'railing',
    'gallantry',
    'gallantry1',
    'gallantry2',
    'bending',
    'welding',
    'kazimierz',
    'studding',
    'storageWrapper',
    'gearRail',
    'storage',
    'mountingStrip',
  ];
  unsortedToolsArray.sort((a, b) => {
    const aIndex = orderedArray.indexOf(a);
    const bIndex = orderedArray.indexOf(b);
    if (aIndex === -1 && bIndex === -1) {
      return 0;
    }
    if (aIndex === -1) {
      return 1;
    }
    if (bIndex === -1) {
      return -1;
    }
    return aIndex - bIndex;
  });

  // TODO: to narazie mi nie dziala - przemyslec
  // const toolsToBeRemoved = [
  //   'spacingLeft',
  //   'spacingRight',
  //   'info',
  //   'spacing',
  // ];
  // unsortedToolsArray.filter((it) => !toolsToBeRemoved.includes(it));
};

const getAssymetry = (r) => {
  if (r.typ === 'uchylna') {
    if (r.asymetria === 'BRAMO-FURTKA') {
      return 'Bramo-furtka';
    } if (r.asymetria === 'BRAMA NIESYMETRYCZNA') {
      return 'Brama niesymetryczna';
    } if (r.asymetria === 'BRAMA JEDNOSKRZYDŁOWA') {
      return 'Brama jednoskrzydłowa';
    }
    return 'Brama uchylna';
  } if (r.typ === 'lamana') {
    if (r.asymetria === 'BRAMA JEDNOSKRZYDŁOWA') {
      return 'Brama łamana jednoskrzydłowa';
    }
  }
  return r.typ;
};

const ProductionMaterials = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [ordersIds, setOrdersIds] = useState([orderId]);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const q = new URLSearchParams(useLocation().search);
  const [fetched, setFetched] = useState(false);
  const nextOrderInput = useRef(null);
  const [itemsToHide, setItemsToHide] = useState([]);
  const [clickedRows, setClickedRows] = useState({});

  const [isOpen, setIsOpen] = useState([orderId]);
  const [items, setItems] = useState([]);
  const [tools, setTools] = useState([]);
  const [filterArray, setFilterArray] = useState([]);
  const [doneArray, setDoneArray] = useState([]);
  const [toolFilterArray, setToolFilterArray] = useState([]);
  const [shouldMerge, setShouldMerge] = useState(false);
  const [generalInfoButtonActive, setgeneralInfoButtonActive] = useState(true);
  const products = useSelector((s) => s.app.productionMaterials?.map((p) => p.products))?.flat(2);
  const ordersNames = useSelector((s) => s.app.productionMaterials?.map((p) => p.zamowieniaId))?.flat(2);
  const productsByOrder = useSelector((s) => s.app.productionMaterials?.map(((p) => ({ products: p.products, order: p.zamowieniaId }))));

  useEffect(() => {
    dispatch(fetchProductionMaterialsMulti(ordersIds, q.get('env'), () => setFetched(true)));
  }, [dispatch, ordersIds]);

  useEffect(() => {
    if (!products || products.length <= 0) return;

    const t = products?.filter((p) => p.productionMaterials).map((p) => Object.keys(p?.productionMaterials).filter((key) => !Array.isArray(p?.productionMaterials[key] || p?.productionMaterials[key].length > 0))).flat(2);
    const i = products?.map((p) => p.rawArray.typ).flat(2); // flatten result
    const id = products.filter((pM) => pM.productionMaterials !== null).map((pM) => pM.id);
    if (Array.from(new Set(t)).length !== tools.length || Array.from(new Set(i)).length !== items.length) {
      setItems(Array.from(new Set(i))); // remove duplicates
      setTools(Array.from(new Set(t))); // remove duplicates
      setFilterArray(Array.from(new Set(id)));
      setDoneArray(Array.from(new Set(id)));
      setToolFilterArray(Array.from(new Set(t)));
    }
  }, [products]);

  // sortujemy
  sortAndFilterToolsArray(tools);
  sortAndFilterToolsArray(toolFilterArray);

  if (!fetched && !products) {
    return (
      <Container className="container">
        <div className="row">
          <div className="col">
            <h1>Trwa pobieranie karty produkcyjnej...</h1>
            <img src={animatedGate} alt="Loading..." />
          </div>
        </div>
      </Container>
    );
  }

  if (fetched && (!products || products.length === 0)) {
    return (
      <Container className="container">
        <div className="row">
          <div className="col">
            <h1>Brak karty produkcyjnej</h1>
          </div>
        </div>
      </Container>
    );
  }

  const allProductionMaterialsIDs = products.filter((p) => p.productionMaterials !== null).map((p) => p.id);

  const FencesSwitchOver = () => { setFilterArray((fa) => (fa.length ? [] : allProductionMaterialsIDs)); };
  const ToolsSwitchOver = () => { setToolFilterArray((fa) => (fa.length ? [] : tools)); };

  const FencesSwitchOverButton = () => (
    <ButtonNav
      style={{ marginBottom: '10px' }}
      key={Math.random()}
      type="button"
      className="btn btn-secondary btn-block"
      onClick={() => {
        FencesSwitchOver();
      }}
      data-toggle="tooltip"
      data-placement="right"
      title="Wyświetl/Ukryj cały asortyment"
    >
      Przełącz Asortyment
    </ButtonNav>
  );

  const ToolsSwitchOverButton = () => (
    <ButtonNav
      key={Math.random()}
      type="button"
      className="btn btn-secondary btn-block"
      onClick={() => {
        ToolsSwitchOver();
      }}
    >
      Przełącz działy
    </ButtonNav>
  );

  const CollapsibleSection = ({ title, id, children }) => {
    const toggleOpen = () => {
      setIsOpen((prevIsOpen) => {
        if (prevIsOpen.includes(id)) {
          return prevIsOpen.filter((sectionId) => sectionId !== id);
        }
        return [...prevIsOpen, id];
      });
    };
    return (
      <Collapsible>
        <CollapsibleHeader className="collapsible-header" onClick={toggleOpen}>
          <span><b style={{ fontSize: '19px' }}>{title} </b></span>
          <span className="collapsible-icon">
            {isOpen.indexOf(id) !== -1 ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        </CollapsibleHeader>
        {isOpen.indexOf(id) !== -1 && <div className="collapsible-content">{children}</div>}
      </Collapsible>
    );
  };

  const FenceTypeButtonByOrder = (p) => p.products.map((pM) => (
    resolveItemName(getAssymetry(pM.rawArray)) === 'slupy' || resolveItemName(getAssymetry(pM.rawArray)) === 'Akcesoria' || resolveItemName(getAssymetry(pM.rawArray)) === 'Słupek funkcjonalny' || resolveItemName(getAssymetry(pM.rawArray)) === 'xps' ? null
      : (
        <Tab
          key={Math.random()}
          type="button"
          className={filterArray.includes(pM.id) ? 'active' : ''}
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            filterArray.includes(pM.id) ? setFilterArray(filterArray.filter((a) => a !== pM.id)) : setFilterArray([...filterArray, pM.id]);
          }}
        >
          <FaRegDotCircle />
          {` Lp.${pM.order} - `}
          {' '}
          {resolveItemName(getAssymetry(pM.rawArray))}
          {' '}
          H {(pM.rawArray.wysokosc === 'inna' || pM.rawArray.wysokosc === 0) ? pM.rawArray.inna_wysokosc : pM.rawArray.wysokosc} L {pM.rawArray.szerokosc}
          {' '}
          {pM.rawArray?.model}
        </Tab>
      )));

  const FenceTypeButton = () => productsByOrder.map((p) => (
    <div className="column">
      <div className="row">
        <CollapsibleSection title={`Zamówienie nr ${p.order}`} id={p.order}>
          {FenceTypeButtonByOrder(p)}
        </CollapsibleSection>
      </div>
    </div>
  ));

  const RenderProductionOrderErrors = (p) => p.products.map((pM) => (
    pM.productionMaterials !== null || resolveItemName(getAssymetry(pM.rawArray)) === 'Słupek funkcjonalny' || resolveItemName(getAssymetry(pM.rawArray)) === 'Akcesoria' || resolveItemName(getAssymetry(pM.rawArray)) === 'slupy' || resolveItemName(getAssymetry(pM.rawArray)) === 'xps' ? null : (
      <>
        {`[${p.order}] `}
        {resolveItemName(getAssymetry(pM.rawArray))}
        {'  '}
        H {pM.rawArray.wysokosc === 'inna' ? pM.rawArray.inna_wysokosc : pM.rawArray.wysokosc} L {pM.rawArray.szerokosc}
        {' : Wystąpił błąd podczas przeliczania materiałów.'}
      </>
    )
  ));

  const RenderProductionErrors = () => productsByOrder.map((p) => (
    <div>
      <p>
        {RenderProductionOrderErrors(p)}
      </p>
    </div>
  ));

  const RenderProductsGeneralInformation = () => products.map((pM) => {
    // if (!filterArray.includes(pM.id)) return null;
    if (pM.productionMaterials === null) return null;

    // let gateIsRectangle = '';
    // let gateIsRectangleTitle = '';
    // if (pM.productionMaterials?.info?.gateIsRectangle) {
    //   gateIsRectangleTitle = 'Prostokątna brama samonośna: ';
    //   gateIsRectangle = pM.productionMaterials.info.gateIsRectangle;
    // }

    let totalGateLength = '';
    let totalGateLengthTitle = '';
    if (pM.rawArray.typ === 'samonosna' && pM.rawArray.szerokosc <= 5800 && pM.productionMaterials?.saw?.rail) {
      totalGateLengthTitle = 'Całkowita długość bramy: ';
      totalGateLength = `${pM.productionMaterials.saw?.rail?.size?.value + 20} mm`;
    }
    if (pM.rawArray.typ === 'samonosna' && pM.rawArray?.przeciwwaga_samonosna) {
      totalGateLengthTitle = 'Całkowita długość bramy: ';
      totalGateLength = `${pM.rawArray.przeciwwaga_samonosna}`;
    }

    let firstGateHalf = '';
    let secondGateHalf = '';
    let gateHalfTitle = '';
    if (pM.rawArray.typ === 'samonosna' && pM.rawArray.szerokosc > 5800 && pM.rawArray.szerokosc <= 7500) {
      gateHalfTitle = 'BOK: ';
      const firstGateHalfSize = pM.productionMaterials?.gearRail?.gearRail === 'NIE DOTYCZY' ? pM.productionMaterials.saw?.topFrontHorizontalFrameWidth?.size.value : pM.productionMaterials.saw?.bottomZProfile?.size.value + 200;
      const secondGateHalfSize = pM.productionMaterials.saw?.backRail?.size.value;
      const firstGateHalfPrefix = firstGateHalfSize > secondGateHalfSize ? 'Dłuższa ' : 'Krótsza ';
      const secondGateHalfPrefix = secondGateHalfSize > firstGateHalfSize ? 'Dłuższa ' : 'Krótsza ';

      firstGateHalf = `${firstGateHalfPrefix} połówka bramy: ${firstGateHalfSize} mm |`;
      secondGateHalf = ` ${secondGateHalfPrefix} połówka bramy: ${secondGateHalfSize} mm`;
    }

    let spacingTitle = '';
    let spacing = '';
    if (pM.productionMaterials?.spacing) {
      spacingTitle = 'Rozstaw: ';
      if (pM.productionMaterials.spacing.size?.value) {
        spacing = `${pM.productionMaterials.spacing.size.value} mm`;
      } else {
        spacing = ` ${Math.round(pM.productionMaterials.spacing * 100) / 100} mm`;
      }
    }

    let hingesTitle = '';
    let hinges = '';
    if (pM.rawArray?.zawiasy) {
      if (pM.rawArray?.zawias_180 && pM.rawArray?.zawias_180.toLowerCase() === 'tak') {
        hingesTitle = 'Zawiasy: ';
        hinges = '180 stopni';
      } else {
        hingesTitle = 'Zawiasy: ';
        hinges = pM.rawArray.zawiasy;
      }
    }

    let gateDirectionTitle = '';
    let gateDirection = '';
    if (pM.rawArray?.kierunek_samonosna) {
      gateDirectionTitle = 'Kierunek bramy: ';
      gateDirection = pM.rawArray.kierunek_samonosna;
    }

    let fillingDistanceTitle = '';
    let fillingDistance = '';
    if (pM.productionMaterials?.info && 'odleglość od wypelnienia do końca poziomu' in pM.productionMaterials.info && pM.productionMaterials?.info['odleglość od wypelnienia do końca poziomu']) {
      fillingDistanceTitle = 'Odległość od wypełnienia do końca poziomu: ';
      fillingDistance = ` ${pM.productionMaterials?.info['odleglość od wypelnienia do końca poziomu']} mm`;
    }

    let clampsTitle = '';
    let clamps = '';
    if (pM.rawArray?.obejmy_przesla) {
      clampsTitle = 'Uchwyt montażowy: ';
      clamps = pM.rawArray.obejmy_przesla.toLowerCase();
    }
    if (pM.rawArray?.wymiar_na_gotowo && pM.rawArray?.wymiar_na_gotowo === 'TAK') {
      clampsTitle = 'Uchwyt montażowy: ';
      clamps = 'Wymiar na gotowo';
    }

    let P46spacingFromVerticalFrameLockTitle = '';
    let P46spacingFromVerticalFrameLock = '';
    if (pM.productionMaterials?.welding?.spacingFromVerticalFrameLock) {
      P46spacingFromVerticalFrameLockTitle = ' Odstęp od pionu zamkowego: ';
      P46spacingFromVerticalFrameLock = `${pM.productionMaterials?.welding?.spacingFromVerticalFrameLock} mm`;
    }

    let P46spacingFromVerticalFrameLockLeftTitle = '';
    let P46spacingFromVerticalFrameLockLeft = '';
    if (pM.productionMaterials?.welding?.leftWing?.spacingFromVerticalFrameLock) {
      P46spacingFromVerticalFrameLockLeftTitle = ' Odstęp od pionu zamkowego (lewe skrzydło): ';
      P46spacingFromVerticalFrameLockLeft = `${pM.productionMaterials?.welding?.leftWing?.spacingFromVerticalFrameLock} mm`;
    }

    let P46spacingFromVerticalFrameLockRightTitle = '';
    let P46spacingFromVerticalFrameLockRight = '';
    if (pM.productionMaterials?.welding?.rightWing?.spacingFromVerticalFrameLock) {
      P46spacingFromVerticalFrameLockRightTitle = ' Odstęp od pionu zamkowego (prawe skrzydło): ';
      P46spacingFromVerticalFrameLockRight = `${pM.productionMaterials?.welding?.rightWing?.spacingFromVerticalFrameLock} mm`;
    }

    let P46spacingFromVerticalFrameHingeTitle = '';
    let P46spacingFromVerticalFrameHinge = '';
    if (pM.productionMaterials?.welding?.spacingFromVerticalFrameHinge) {
      P46spacingFromVerticalFrameHingeTitle = 'Odstęp od pionu zawiasowego: ';
      P46spacingFromVerticalFrameHinge = `${pM.productionMaterials.welding?.spacingFromVerticalFrameHinge} mm`;
    }

    let P46spacingFromVerticalFrameHingeLeftTitle = '';
    let P46spacingFromVerticalFrameHingeLeft = '';
    if (pM.productionMaterials?.welding?.leftWing?.spacingFromVerticalFrameHinge) {
      P46spacingFromVerticalFrameHingeLeftTitle = 'Odstęp od pionu zawiasowego: ';
      P46spacingFromVerticalFrameHingeLeft = `${pM.productionMaterials.welding?.leftWing?.spacingFromVerticalFrameHinge} mm`;
    }

    let P46spacingFromVerticalFrameHingeRightTitle = '';
    let P46spacingFromVerticalFrameHingeRight = '';
    if (pM.productionMaterials?.welding?.rightWing?.spacingFromVerticalFrameHinge) {
      P46spacingFromVerticalFrameHingeRightTitle = 'Odstęp od pionu zawiasowego: ';
      P46spacingFromVerticalFrameHingeRight = `${pM.productionMaterials.welding?.rightWing?.spacingFromVerticalFrameHinge} mm`;
    }

    let P46spacingAreaOneLeftTitle = '';
    let P46spacingAreaOneLeft = '';
    if (pM.productionMaterials?.welding?.leftWing?.areaOneWidth) {
      P46spacingAreaOneLeftTitle = 'Szerokość pierwszego pola: ';
      P46spacingAreaOneLeft = `${pM.productionMaterials.welding?.leftWing?.areaOneWidth} mm`;
    }

    let P46spacingAreaTwoLeftTitle = '';
    let P46spacingAreaTwoLeft = '';
    if (pM.productionMaterials?.welding?.leftWing?.areaTwoWidth) {
      P46spacingAreaTwoLeftTitle = 'Szerokość drugiego pola: ';
      P46spacingAreaTwoLeft = `${pM.productionMaterials.welding?.leftWing?.areaTwoWidth} mm`;
    }

    let P46spacingAreaOneRightTitle = '';
    let P46spacingAreaOneRight = '';
    if (pM.productionMaterials?.welding?.rightWing?.areaOneWidth) {
      P46spacingAreaOneRightTitle = 'Szerokość pierwszego pola: ';
      P46spacingAreaOneRight = `${pM.productionMaterials.welding?.rightWing?.areaOneWidth} mm`;
    }

    let P46spacingAreaTwoRightTitle = '';
    let P46spacingAreaTwoRight = '';
    if (pM.productionMaterials?.welding?.rightWing?.areaTwoWidth) {
      P46spacingAreaTwoRightTitle = 'Szerokość drugiego pola: ';
      P46spacingAreaTwoRight = `${pM.productionMaterials.welding?.rightWing?.areaTwoWidth} mm`;
    }

    let P46spacingFromVerticalFrameCounterweightTitle = '';
    let P46spacingFromVerticalFrameCounterweight = '';
    if (pM.productionMaterials?.welding?.spacingFromVerticalFrameCounterweight) {
      P46spacingFromVerticalFrameCounterweightTitle = 'Odstęp od pionu przeciwwagi: ';
      P46spacingFromVerticalFrameCounterweight = `${pM.productionMaterials.welding?.spacingFromVerticalFrameCounterweight} mm`;
    }

    let P46spacingAreaOneTitle = '';
    let P46spacingAreaOne = '';
    if (pM.productionMaterials?.welding?.areaOneWidth) {
      P46spacingAreaOneTitle = 'Szerokość pierwszego pola: ';
      P46spacingAreaOne = `${pM.productionMaterials.welding?.areaOneWidth} mm`;
    }

    let P46spacingAreaTwoTitle = '';
    let P46spacingAreaTwo = '';
    if (pM.productionMaterials?.welding?.areaTwoWidth) {
      P46spacingAreaTwoTitle = 'Szerokość drugiego pola: ';
      P46spacingAreaTwo = `${pM.productionMaterials.welding?.areaTwoWidth} mm`;
    }

    let P46spacingAreaThreeTitle = '';
    let P46spacingAreaThree = '';
    if (pM.productionMaterials?.welding?.areaThreeWidth) {
      P46spacingAreaThreeTitle = 'Szerokość trzeciego pola: ';
      P46spacingAreaThree = `${pM.productionMaterials.welding?.areaThreeWidth} mm`;
    }

    let P46spacingAreaFourTitle = '';
    let P46spacingAreaFour = '';
    if (pM.productionMaterials?.welding?.areaFourWidth && pM.productionMaterials.welding.areaFourWidth !== 'NIE DOTYCZY') {
      P46spacingAreaFourTitle = 'Szerokość czwartego pola: ';
      P46spacingAreaFour = `${pM.productionMaterials.welding?.areaFourWidth} mm`;
    }

    let spacingFromConstructionTitle = '';
    let spacingFromConstruction = '';
    if (pM.productionMaterials?.info?.spacingFromConstruction) {
      spacingFromConstructionTitle = `${pM.productionMaterials?.info?.spacingFromConstruction.name}: `;
      spacingFromConstruction = `${pM.productionMaterials?.info?.spacingFromConstruction.size.value} mm`;
    }

    let handRailTitle = '';
    let handRail = '';
    if (pM.rawArray?.pochwyt) {
      handRailTitle = 'Pochwyt: ';
      handRail = pM.rawArray.pochwyt;
    }

    const resolveHingeName = (h) => {
      switch (h.toLowerCase()) {
        case 'wklejane': return 'standard/wklejane';
        case 'przykręcane': return 'standard/wklejane';
        default: return h;
      }
    };

    const resolveHeight = (pM.rawArray.wysokosc === 'inna' || pM.rawArray.wysokosc === 0) ? pM.rawArray.inna_wysokosc : pM.rawArray.wysokosc;
    const model = pM.rawArray?.verticale_rodzaj ? pM.rawArray?.verticale_rodzaj : pM.rawArray.model;

    let productionHeightTitle = '';
    let productionHeight = '';
    if ((pM.rawArray.model === 'PP002(P82_0)' || pM.rawArray.model === 'PP002(P102_0)') && pM.productionMaterials?.info?.productionHeight) {
      productionHeightTitle = 'Wysokość produkcji: ';
      productionHeight = `${pM.productionMaterials.info.productionHeight.value} mm`;
    }

    const hasMailbox = ['PB003', 'PP001(C)', 'PP001(CC)', 'PP001(N)', 'PP001(P46)', 'PP001(W)',
      'PP002', 'PP002(HDG)', 'PP002(D)', 'PP002(D)1', 'PP002(D)2', 'PP002(D)3',
      'PP002(P)', 'PP002(P102)', 'PP002(P102_0)', 'PP002(P64)', 'PP002(P64V)', 'PP002(P82)', 'PP002(P82_0)',
      'PP002(P82)C1', 'PP002(P82)C2', 'PP002(P82)MIX', 'PP002(P82) NOCE I', 'PP002(P82) NOCE II', 'PP002(P82) PINO I',
      'PP002(P82) PINO II', 'Verticale'];

    let mailboxInfoTitle = '';
    let mailboxInfo = '';
    if (pM.rawArray.type === 'span' && hasMailbox.includes(pM.rawArray.model) && pM.rawArray?.knsMailbox) {
      mailboxInfoTitle = 'Skrzynka na listy: ';
      mailboxInfo = pM.rawArray.knsMailbox;
    }

    const handleSwitchChange = () => {
      if (doneArray.includes(pM.id)) {
        setDoneArray(doneArray.filter((a) => a !== pM.id));
      } else {
        setDoneArray([...doneArray, pM.id]);
      }
    };

    return (
      <div>
        <p>
          <h5 style={{ fontSize: '110%', fontWeight: 'bold' }}><></>
            {' | Lp.'}{pM.order}
            {' | '}
            {resolveItemName(getAssymetry(pM.rawArray))}
            {' ('}
            H: {resolveHeight} L: {pM.rawArray.szerokosc}
            {') - '}
            Ilość: {pM.rawArray.ilosc} szt | Model: {model} | {pM.zamowieniaId}
            {' | '}
            <input
              type="checkbox"
              id={`switch-${pM.id}`}
              onClick={handleSwitchChange}
              checked={!doneArray.includes(pM.id)}
            />
          </h5>
          {pM.productionMaterials?.info && pM.productionMaterials.info?.areaHeight
            && (
              <div>
                <b> Wysokość pola [H]: </b>
                {pM.productionMaterials.info.areaHeight?.value} mm |
                <b> Szerokość pola [L]: </b>
                {pM.productionMaterials.info.areaWidth?.value} mm |
                <b> Ilość pól: </b>
                {pM.productionMaterials.info.areaAmount?.value} szt
              </div>
            )}
          {pM.productionMaterials?.info && pM.productionMaterials.info?.leftWing?.areaHeight
            && (
              <div>
                <b>LEWE SKRZYDŁO</b>
                <br />
                <b> Wysokość pola [H]: </b>
                {pM.productionMaterials.info.leftWing.areaHeight?.value} mm |
                <b> Szerokość pola [L]: </b>
                {pM.productionMaterials.info.leftWing.areaWidth?.value} mm |
                <b> Ilość pól: </b>
                {pM.productionMaterials.info.leftWing.areaAmount?.value} szt
              </div>
            )}
          {pM.productionMaterials?.info && pM.productionMaterials.info?.rightWing?.areaHeight
            && (
              <div>
                <b>PRAWE SKRZYDŁO</b>
                <br />
                <b> Wysokość pola [H]: </b>
                {pM.productionMaterials.info.rightWing.areaHeight?.value} mm |
                <b> Szerokość pola [L]: </b>
                {pM.productionMaterials.info.rightWing.areaWidth?.value} mm |
                <b> Ilość pól: </b>
                {pM.productionMaterials.info.rightWing.areaAmount?.value} szt
              </div>
            )}
          {pM.productionMaterials?.infoAboveMachine && pM.productionMaterials.infoAboveMachine?.areaHeight
            && (
              <div>
                <b> Wysokość pola nad automatem [H]: </b>
                {pM.productionMaterials.infoAboveMachine.areaHeight?.value} mm |
                <b> Szerokość pola nad automatem [L]: </b>
                {pM.productionMaterials.infoAboveMachine.areaWidth?.value} mm |
                <b> Ilość pól nad automatem: </b>
                {pM.productionMaterials.infoAboveMachine.areaAmount?.value} szt
              </div>
            )}
          {pM.productionMaterials?.infoUnderMachine && pM.productionMaterials.infoUnderMachine?.areaHeight
            && (
              <div>
                <b> Wysokość pola pod automatem [H]: </b>
                {pM.productionMaterials.infoUnderMachine.areaHeight?.value} mm |
                <b> Szerokość pola pod automatem [L]: </b>
                {pM.productionMaterials.infoUnderMachine.areaWidth?.value} mm |
                <b> Ilość pól pod automatem: </b>
                {pM.productionMaterials.infoUnderMachine.areaAmount?.value} szt
              </div>
            )}
          {pM.productionMaterials?.info && pM.productionMaterials.infoAboveMachine?.leftWing?.areaHeight
            && (
              <div>
                <b>LEWE SKRZYDŁO</b>
                <br />
                <b> Wysokość pola nad automatem [H]: </b>
                {pM.productionMaterials.infoAboveMachine.leftWing.areaHeight?.value} mm |
                <b> Szerokość pola nad automatem [L]: </b>
                {pM.productionMaterials.infoAboveMachine.leftWing.areaWidth?.value} mm |
                <b> Ilość pól nad automatem: </b>
                {pM.productionMaterials.infoAboveMachine.leftWing.areaAmount?.value} szt
              </div>
            )}
          {pM.productionMaterials?.info && pM.productionMaterials.infoUnderMachine?.leftWing?.areaHeight
            && (
              <div>
                <b> Wysokość pola pod automatem [H]: </b>
                {pM.productionMaterials.infoUnderMachine.leftWing.areaHeight?.value} mm |
                <b> Szerokość pola pod automatem [L]: </b>
                {pM.productionMaterials.infoUnderMachine.leftWing.areaWidth?.value} mm |
                <b> Ilość pól pod automatem: </b>
                {pM.productionMaterials.infoUnderMachine.leftWing.areaAmount?.value} szt
              </div>
            )}
          {pM.productionMaterials?.info && pM.productionMaterials.infoAboveMachine?.rightWing?.areaHeight
            && (
              <div>
                <b>PRAWE SKRZYDŁO</b>
                <br />
                <b> Wysokość pola nad automatem [H]: </b>
                {pM.productionMaterials.infoAboveMachine.rightWing.areaHeight?.value} mm |
                <b> Szerokość pola nad automatem [L]: </b>
                {pM.productionMaterials.infoAboveMachine.rightWing.areaWidth?.value} mm |
                <b> Ilość pól nad automatem: </b>
                {pM.productionMaterials.infoAboveMachine.rightWing.areaAmount?.value} szt
              </div>
            )}
          {pM.productionMaterials?.info && pM.productionMaterials.infoUnderMachine?.rightWing?.areaHeight
            && (
              <div>
                <b> Wysokość pola pod automatem [H]: </b>
                {pM.productionMaterials.infoUnderMachine.rightWing.areaHeight?.value} mm |
                <b> Szerokość pola pod automatem [L]: </b>
                {pM.productionMaterials.infoUnderMachine.rightWing.areaWidth?.value} mm |
                <b> Ilość pól pod automatem: </b>
                {pM.productionMaterials.infoUnderMachine.rightWing.areaAmount?.value} szt
              </div>
            )}
          <b>{spacingTitle}</b>
          {spacing}
          {' '}
          <b>{fillingDistanceTitle}</b>
          {fillingDistance}
          {' '}
          <b>{hingesTitle}</b>
          {resolveHingeName(hinges)}
          <b>{clampsTitle}</b>
          {clamps}
          {' '}
          <b>{mailboxInfoTitle}</b>
          {mailboxInfo}
          {' '}
          <b>{P46spacingFromVerticalFrameLockTitle}</b>
          {P46spacingFromVerticalFrameLock}
          {' '}
          <b>{P46spacingFromVerticalFrameHingeTitle}</b>
          {P46spacingFromVerticalFrameHinge}
          {' '}
          <b>{P46spacingFromVerticalFrameLockLeftTitle}</b>
          {P46spacingFromVerticalFrameLockLeft}
          {' '}
          <b>{P46spacingFromVerticalFrameHingeLeftTitle}</b>
          {P46spacingFromVerticalFrameHingeLeft}
          {' '}
          <b>{P46spacingAreaOneLeftTitle}</b>
          {P46spacingAreaOneLeft}
          {' '}
          <b>{P46spacingAreaTwoLeftTitle}</b>
          {P46spacingAreaTwoLeft}
          {' '}
          <b>{P46spacingFromVerticalFrameLockRightTitle}</b>
          {P46spacingFromVerticalFrameLockRight}
          {' '}
          <b>{P46spacingFromVerticalFrameHingeRightTitle}</b>
          {P46spacingFromVerticalFrameHingeRight}
          {' '}
          <b>{P46spacingAreaOneRightTitle}</b>
          {P46spacingAreaOneRight}
          {' '}
          <b>{P46spacingAreaTwoRightTitle}</b>
          {P46spacingAreaTwoRight}
          {' '}
          <b>{P46spacingFromVerticalFrameCounterweightTitle}</b>
          {P46spacingFromVerticalFrameCounterweight}
          {' '}
          <b>{P46spacingAreaOneTitle}</b>
          {P46spacingAreaOne}
          {' '}
          <b>{P46spacingAreaTwoTitle}</b>
          {P46spacingAreaTwo}
          {' '}
          <b>{P46spacingAreaThreeTitle}</b>
          {P46spacingAreaThree}
          {' '}
          <b>{P46spacingAreaFourTitle}</b>
          {P46spacingAreaFour}
          {' '}
          <b>{spacingFromConstructionTitle}</b>
          {spacingFromConstruction}
          {' '}
          <div>
            <b>
              {gateDirectionTitle}
            </b>
            {gateDirection}
            {' '}
          </div>
          <div>
            <b>
              {totalGateLengthTitle}
            </b>
            {totalGateLength}
          </div>
          <div>
            {pM.productionMaterials?.info?.firstAreaWidth && (
              <>
                <b>
                  {`${pM.productionMaterials.info.firstAreaWidth.name}: `}
                </b>
                {`${pM.productionMaterials.info.firstAreaWidth.size.value} mm`}
              </>
            )}
            {pM.productionMaterials?.info?.secondAreaWidth && (
              <>
                <b>
                  {` | ${pM.productionMaterials.info.secondAreaWidth.name}: `}
                </b>
                {`${pM.productionMaterials.info.secondAreaWidth.size.value} mm`}
              </>
            )}
            {pM.productionMaterials?.info?.thirdAreaWidth && (
              <>
                <b>
                  {` | ${pM.productionMaterials.info.thirdAreaWidth.name}: `}
                </b>
                {`${pM.productionMaterials.info.thirdAreaWidth.size.value} mm`}
              </>
            )}
          </div>
          <div>
            <b>
              {gateHalfTitle}
            </b>
            {firstGateHalf}
            {secondGateHalf}
          </div>
          <div>
            <b>
              {productionHeightTitle}
            </b>
            {productionHeight}
          </div>
          <div>
            <b>
              {handRailTitle}
            </b>
            {handRail}
          </div>
        </p>
      </div>
    );
  });

  const toogleGeneralInfo = () => {
    const generalInfoDiv = document.getElementById('general_info');

    if (generalInfoDiv.style.display === 'block') {
      generalInfoDiv.style.display = 'none';
    } else {
      generalInfoDiv.style.display = 'block';
    }
  };

  const tooglePrintView = () => {
    const addButton = document.getElementById('add');
    const fencesDiv = document.getElementById('fences-fences');
    const toolsDiv = document.getElementById('fences-tools');
    // const mainView = document.getElementById('mainSection');
    // if (mainView.style.marginLeft === '400px') {
    //   mainView.style.marginLeft = '';
    // } else {
    //   mainView.style.marginLeft = '400px';
    // }
    if (fencesDiv.style.display === 'none') {
      fencesDiv.style.display = 'block';
    } else {
      fencesDiv.style.display = 'none';
    }
    if (toolsDiv.style.display === 'block') {
      toolsDiv.style.display = 'none';
    } else {
      toolsDiv.style.display = 'block';
    }
    if (addButton.style.display === '') {
      addButton.style.display = 'none';
    } else {
      addButton.style.display = '';
    }
  };

  const generateXLS = () => {
    GenerateXLS(products, ordersNames);
  };

  const print = () => window.print();

  return (
    <>
      <Navbar>
        <nav>
          <img width="200" height="40" className="d-inline-block align-top" src="https://kns-assets-common.s3-eu-west-1.amazonaws.com/img/logo.png" alt="logo" />
          <ButtonNav type="button" className={shouldMerge ? 'btn btn-success' : 'btn btn-danger'} onClick={() => { setShouldMerge(!shouldMerge); }}>Widok skonsolidowany</ButtonNav>
          <ButtonNav type="button" className="btn btn-danger" onClick={print}>Drukuj</ButtonNav>
          <ButtonNav type="button" className="btn btn-danger" onClick={tooglePrintView}>Tryb druku</ButtonNav>
          <ButtonNav type="button" className="btn btn-danger" onClick={generateXLS}>Generuj xls</ButtonNav>
          <b style={{ fontSize: '20px' }}>
            Zamówienia:
            {' '}
            {ordersNames.join(', ')}
            {(!isAddingNew && !isRemoving)
              ? (
                <ButtonAdd
                  id="add"
                  onClick={() => setIsAddingNew(true)}
                >
                  <FaPlus />
                </ButtonAdd>
              ) : <></>}
            {(!isRemoving && !isAddingNew)
              ? (
                <ButtonRemove
                  id="remove"
                  onClick={() => setIsRemoving(true)}
                >
                  <FaMinus />
                </ButtonRemove>
              ) : <></>}
            {isAddingNew ? (
              <>
                <input type="text" placeholder="Kolejne zamowienie" ref={nextOrderInput} style={{ marginLeft: '30px' }} />
                <ButtonAdd onClick={async () => {
                  if (nextOrderInput?.current?.value.length >= 5) {
                    const { data } = await axios.get(`/zamowienia/${nextOrderInput?.current?.value}/get-order-id`);
                    setOrdersIds([...ordersIds, data?.order?.id]);
                    nextOrderInput.current.value = '';
                  }
                  setIsAddingNew(false);
                }}
                >
                  Dodaj
                </ButtonAdd>
              </>
            ) : <></>}
            {isRemoving ? (
              <>
                <input type="text" placeholder="Usuń zamowienie" ref={nextOrderInput} style={{ marginLeft: '30px' }} />
                <ButtonRemove onClick={async () => {
                  if (nextOrderInput?.current?.value.length >= 5) {
                    const id = nextOrderInput?.current?.value;
                    if (ordersIds.includes(id)) {
                      setOrdersIds(ordersIds.filter((a) => a !== id));
                    }
                  }
                  setIsRemoving(false);
                }}
                >
                  Usuń
                </ButtonRemove>
              </>
            ) : <></>}
          </b>
        </nav>
      </Navbar>
      <Containerside>
        <Sidebar id="fences-fences">
          <FencesSwitchOverButton />
          <FenceTypeButton />
        </Sidebar>
        {/* <Main id="mainSection" style={{ marginLeft: '400px' }}> */}
        <Main id="mainSection">
          <Topsection>
            <RenderProductionErrors />
            <div id="fences-tools" style={{ display: 'block' }}>
              <ToolsSwitchOverButton />
              <SubTab
                type="button"
                className={generalInfoButtonActive ? 'active' : ''}
                onClick={() => {
                  // eslint-disable-next-line no-unused-expressions
                  toogleGeneralInfo(); setgeneralInfoButtonActive(!generalInfoButtonActive);
                }}
              >
                Informacje Ogólne
              </SubTab>
              {tools.map((t) => {
                // tych przyciskow nie potrzebujemy
                if (t === 'info') return null;
                if (t === 'spacing') return null;
                if (t === 'spacingRight' || t === 'spacingLeft') return null;
                if (t === 'infoAboveMachine' || t === 'infoUnderMachine') return null;
                return (
                  <SubTab
                    type="button"
                    className={toolFilterArray.includes(t) ? 'active' : ''}
                    onClick={() => {
                      // eslint-disable-next-line no-unused-expressions
                      toolFilterArray.includes(t) ? setToolFilterArray(toolFilterArray.filter((a) => a !== t)) : setToolFilterArray([...toolFilterArray, t]);
                    }}
                  >
                    {resolveToolName(t)}
                  </SubTab>
                );
              })}
            </div>
          </Topsection>
          <Bottomsection>
            <div id="general_info" style={{ display: 'block' }}>
              <thead>
                <th colSpan="7">INFORMACJE OGÓLNE</th>
              </thead>
              <hr style={{ width: '100%' }} />
              <RenderProductsGeneralInformation />
              <hr style={{ width: '100%' }} />
            </div>
            {toolFilterArray.map((t) => <RenderToolProducts products={products} filterArray={filterArray} doneArray={doneArray} type={t} shouldMerge={shouldMerge} itemsToHide={itemsToHide} setItemsToHide={setItemsToHide} clickedRows={clickedRows} setClickedRows={setClickedRows} />)}
          </Bottomsection>
        </Main>
      </Containerside>
    </>
  );
};

const RenderToolProducts = ({
  products, filterArray, doneArray, type, shouldMerge, itemsToHide, setItemsToHide, clickedRows, setClickedRows,
}) => {
  const renderProducts = products.filter((p) => filterArray.includes(p?.id)).filter((p) => p && p.productionMaterials && p?.productionMaterials.hasOwnProperty(type)).filter((p) => doneArray.includes(p?.id)).map((p) => (
    {
      orderlp: p.order,
      zamowieniaId: p.zamowieniaId,
      product: p?.productionMaterials[type],
      type: getAssymetry(p?.rawArray),
      width: p?.rawArray?.szerokosc,
      height: p?.rawArray.wysokosc,
      otherHeight: p?.rawArray.inna_wysokosc,
      model: p?.rawArray?.model,
      color: p?.rawArray?.color,
    }));
  const handleRowClick = (rowIndex) => {
    setClickedRows((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  };
  let holes = [];
  const leftHoles = [];
  const rightHoles = [];
  const mailboxInfoArray = [];
  const infoAboveMachineArray = [];
  const infoUnderMachineArray = [];
  const mergedProducts = renderProducts.map((p) => Object.keys(p.product).map((t) => p.product[t])).flat(2);
  let joinProducts = [];
  JSON.parse(JSON.stringify(mergedProducts)).forEach((prod) => {
    const exists = joinProducts.some((p) => p.material === prod.material && p.size?.value === prod.size?.value);
    if (exists) {
      joinProducts = joinProducts.map((p) => {
        if (p.material === prod.material && p.size?.value === prod.size?.value && p.amount && p.amount.value) {
          // eslint-disable-next-line no-param-reassign
          p.amount.value += prod.amount.value;
        }
        return p;
      });
    } else {
      joinProducts.push(prod);
    }
  });
  joinProducts = joinProducts.map((p) => ({ type, product: { merged: p } }));
  const arrayToRender = shouldMerge ? joinProducts : renderProducts;
  const renderObjects = (lp) => Object.keys(lp.product).map((key, j) => {
    const resolveHeight = (lp.height === 'inna' || lp.height === 0) ? lp.otherHeight : lp.height;
    const resolveWidth = lp.width === undefined ? lp.otherWidth : lp.width;
    const productDef = `Lp. ${lp.orderlp} | ${resolveItemName(lp.type)} | H: ${resolveHeight} W: ${resolveWidth} | ${lp.model} | ${lp.zamowieniaId}`;
    const index = `${j}-${lp.zamowieniaId}-${lp.orderlp}`;

    if (key.toLowerCase() === 'gearrail') {
      if (lp.product?.gearRailAmount !== 'NIE DOTYCZY' && lp.product?.gearRail !== '#VALUE!' && lp.product?.gearRailAmount > 0) {
        return (
          <tr>
            <td>{j + 1}</td>
            {filterArray.length === 1 ? <td style={{ display: 'none' }}>{resolveItemName(lp.type)}{' '}{resolveHeight}{'x'}{resolveWidth}</td> : <td>{resolveItemName(lp.type)}{' '}{resolveHeight}{'x'}{resolveWidth}</td>}
            <td>{lp.product?.gearRail ? lp.product.gearRail : 'Listwa zębata'}</td>
            <td>Listwa zębata 8x30x1005</td>
            <td>
              {lp.product.gearRailAmount}
              {' '}
              szt
            </td>
            <td>
              1005 mm
            </td>
          </tr>
        );
      }
      return null;
    }
    if (key.toLowerCase() === 'cutgearrailamount') {
      if (lp.product.cutGearRailAmount !== 'NIE DOTYCZY') {
        return (
          <tr>
            <td>{j + 1}</td>
            {filterArray.length === 1 ? <td style={{ display: 'none' }}>{resolveItemName(lp.type)}{' '}{resolveHeight}{'x'}{lp.width}</td> : <td>{resolveItemName(lp.type)}{' '}{resolveHeight}{'x'}{resolveWidth}</td>}
            <td>{lp.product?.cutGearRail ? lp.product.cutGearRail : 'Listwa zębata docinana'}</td>
            <td>Listwa zębata 8x30x1005</td>
            <td>
              {lp.product.cutGearRailAmount}
              {' '}
              szt
            </td>
            <td>
              {lp.product?.cutGearRailSize ? lp.product.cutGearRailSize : 'DOCINANA'}
            </td>
          </tr>
        );
      }
      return null;
    }
    if (!key.toLowerCase().includes('hole') && lp.product[key]?.material) {
      return (
        <tr key={index} className={clickedRows[index] ? 'table-dark' : ''}>
          <td>{j + 1}</td>
          {filterArray.length === 1 ? <td style={{ display: 'none' }}>{resolveItemName(lp.type)}{' '}{resolveHeight}{'x'}{lp.width}</td> : <td>{resolveItemName(lp.type)}{' '}{resolveHeight}{'x'}{resolveWidth}</td>}
          <td>
            {lp.product[key]?.name}
            <button
              type="button"
              className="btn btn-danger"
              style={{ backgroundColor: clickedRows[index] ? 'green' : 'red', marginLeft: '15px' }}
              onClick={() => handleRowClick(index)}
            >
              {clickedRows[index] ? 'Zrobione' : 'W trakcie'}
            </button>
          </td>
          <td>{lp.product[key]?.material}</td>
          <td>
            {lp.product[key]?.amount?.value}
            {' '}
            {lp.product[key]?.amount?.unit}
          </td>
          <td>
            {lp.product[key]?.size?.value}
            {' '}
            {lp.product[key]?.size?.unit}
          </td>
        </tr>
      );
    }
    if (key.toLowerCase().includes('hole')) {
      if (!holes.includes(lp.product)) {
        holes = [
          ...holes,
          { productDef, item: lp.product, index },
        ];
      }
    }
    if ((type === 'palisadeHoles' || type === 'railHoles' || type === 'channelHoles' || type === 'rodHoles' || type === 'palisadeHolesAdd') && key.includes('leftWing')) {
      leftHoles.push({ productDef, item: lp.product.leftWing, index });
    }
    if ((type === 'palisadeHoles' || type === 'railHoles' || type === 'channelHoles' || type === 'rodHoles' || type === 'palisadeHolesAdd') && key.includes('rightWing')) {
      rightHoles.push({ productDef, item: lp.product.rightWing, index });
    }
    if (lp.product.placementOfMailbox) {
      mailboxInfoArray.push({ productDef, item: lp.product[key], index });
    }
    if (key.includes('amountOfHoles')) {
      return (
        <tr key={index} className={clickedRows[index] ? 'table-dark' : ''}>
          <td>1</td>
          {filterArray.length === 1 ? <td style={{ display: 'none' }}>{'Zamówienie: '}{'Lp.'}{lp.orderlp}{' | '}{resolveItemName(lp.type)}{' | H: '}{resolveHeight}{' W: '}{resolveWidth}{' | '}{lp.model}{' | '}{lp.zamowieniaId}</td> : <td>{'Zamówienie: '}{'Lp.'}{lp.orderlp}{' | '}{resolveItemName(lp.type)}{' | H: '}{resolveHeight}{' W: '}{resolveWidth}{' | '}{lp.model}{' | '}{lp.zamowieniaId}</td>}
          <td>
            {lp.product[key]?.name}
            <button
              type="button"
              className="btn btn-danger"
              style={{ backgroundColor: clickedRows[index] ? 'green' : 'red', marginLeft: '15px' }}
              onClick={() => handleRowClick(index)}
            >
              {clickedRows[index] ? 'Zrobione' : 'W trakcie'}
            </button>
          </td>
          <td>{lp.product[key]?.material}</td>
          <td>
            {lp.product[key]?.value}
            {' '}
            {lp.product[key]?.unit}
          </td>
        </tr>
      );
    }
    if (key.includes('spacingBetweenHoles')) {
      return (
        <tr>
          <td>2</td>
          {filterArray.length === 1 ? <td style={{ display: 'none' }}>{'Zamówienie: '}{'Lp.'}{lp.orderlp}{' | '}{resolveItemName(lp.type)}{' | H: '}{resolveHeight}{' W: '}{resolveWidth}{' | '}{lp.model}{' | '}{lp.zamowieniaId}</td> : <td>{'Zamówienie: '}{'Lp.'}{lp.orderlp}{' | '}{resolveItemName(lp.type)}{' | H: '}{resolveHeight}{' W: '}{resolveWidth}{' | '}{lp.model}{' | '}{lp.zamowieniaId}</td>}
          <td>
            {lp.product[key]?.name}
            <button
              type="button"
              className="btn btn-danger"
              style={{ backgroundColor: clickedRows[index] ? 'green' : 'red', marginLeft: '15px' }}
              onClick={() => handleRowClick(index)}
            >
              {clickedRows[index] ? 'Zrobione' : 'W trakcie'}
            </button>
          </td>
          <td>{lp.product[key]?.material}</td>
          <td>
            {lp.product[key]?.value}
            {' '}
            {lp.product[key]?.unit}
          </td>
        </tr>
      );
    }
    return null;
  });
  const toggleItemsToHide = (item) => {
    // eslint-disable-next-line no-unused-expressions
    itemsToHide.includes(item) ? setItemsToHide(itemsToHide.filter((i) => i !== item)) : setItemsToHide([...itemsToHide, item]);
  };
  const rendered = arrayToRender.map((lp, i) => {
    if (lp.product.length === 0) return null;
    const renderedObjects = !itemsToHide.includes(type + i) || shouldMerge ? renderObjects(lp) : null;
    const resolveHeight = (lp.height === 'inna' || lp.height === 0) ? lp.otherHeight : lp.height;
    return (
      <>
        {filterArray.length === 1 ? null
          : (
            <tr>
              <td colSpan={6}>{!itemsToHide.includes(type + i) && !shouldMerge ? (
                <div style={{
                  fontSize: '16px', fontWeight: 600, marginLeft: '10px',
                }}
                >
                  {'Lp.'}{lp.orderlp}{' | '}{resolveItemName(lp.type)}{' | H: '}{resolveHeight}{' W: '}{lp.width}{' | '}{lp.model}{' | '}{lp.zamowieniaId}
                </div>
              ) : <></>}
              </td>
            </tr>
          )}
        {renderedObjects}
      </>
    );
  });

  // todo add array with types that don't use table
  if (rendered[0] === null || !rendered.length) return null;
  return (
    <>
      {(type !== 'palisadeHoles' && type !== 'palisadeHolesAdd' && type !== 'railHoles' && type !== 'spacing'
        && type !== 'mailboxInfo' && type !== 'channelHoles' && type !== 'rodHoles' && type !== 'info'
        && type !== 'infoAboveMachine' && type !== 'infoUnderMachine' && type !== 'welding') && (
          <>
            <table className="table table-hover">
              <thead className="thead-light">
                <tr>
                  <th colSpan="7">
                    <div style={{ display: 'flex' }}>
                      <span style={{ lineHeight: '15px', align: 'center' }}>{resolveSectionHeader(type)}</span>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th className="responseThSingular">Lp.</th>
                  {filterArray.length === 1 ? null : <CustomLpColumnHeader>Produkt</CustomLpColumnHeader>}
                  <th>Nazwa</th>
                  <MaterialColumnHeader>Materiał</MaterialColumnHeader>
                  <AmountColumnHeader>Ilość</AmountColumnHeader>
                  <th>Wymiar</th>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th />
                </tr>
              </thead>
              <tbody>
                {rendered}
              </tbody>
            </table>
            <hr style={{ width: '100%' }} />
          </>
      )}
      {mailboxInfoArray.length > 0 ? <h5>Informacja nt. skrzynki na listy</h5> : null}
      {mailboxInfoArray.map((i) => (
        <>
          <p>
            <b>{i.productDef}</b>
            <button
              type="button"
              className="btn btn-danger"
              style={{ backgroundColor: clickedRows[i.index] ? 'green' : 'red', marginLeft: '15px' }}
              onClick={() => handleRowClick(i.index)}
            >
              {clickedRows[i.index] ? 'Zrobione' : 'W trakcie'}
            </button>
          </p>
          <Holes>
            {i.item.name !== 'Nie dotyczy' && (
              <>
                <span>
                  <b>
                    {i.item.name !== 'Nie dotyczy' && i.item.name}
                    :
                  </b>
                  {' '}
                  {i.item.size?.value}
                  {' '}
                  {i.item.size?.unit}
                </span>
              </>
            )}
          </Holes>
          <hr />
        </>
      ))}
      {/* {infoAboveMachineArray && <span>Informacje nad automatem</span>} */}
      {infoAboveMachineArray.map((i) => (
        <>
          {i.item.name && (
            <>
              <b>
                {i.item.name}
                :
                {' '}
              </b>
              {i.item.value}
              {' '}
              {i.item.unit}
            </>
          )}
        </>
      ))}
      {/* {infoAboveMachineArray && <span>Informacje pod automatem</span>} */}
      {infoUnderMachineArray.map((i) => (
        <>
          {i.item.name && (
            <>
              <b>
                {i.item.name}
                :
                {' '}
              </b>
              {i.item.value}
              {' '}
              {i.item.unit}
            </>
          )}
        </>
      ))}
      {(type === 'palisadeHoles' || type === 'railHoles' || type === 'channelHoles' || type === 'rodHoles' || type === 'palisadeHolesAdd') && (
        <table className="sortable" cellPadding={6}>
          <thead>
            <tr>
              <th colSpan="7">
                <div style={{ display: 'flex' }}>
                  <span style={{ lineHeight: '37px' }}>{resolveHolesName(type)}</span>
                </div>
              </th>
            </tr>
            <tr>
              {(type === 'palisadeHoles' || type === 'railHoles' || type === 'channelHoles' || type === 'rodHoles' || type === 'palisadeHolesAdd') && leftHoles.length > 0 && (
                <span><b>Lewe skrzydło</b></span>
              )}
              {Object.values(leftHoles).map((arr) => (
                <>
                  <p>
                    <b>{arr.productDef}</b>
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ backgroundColor: clickedRows[arr.index] ? 'green' : 'red', marginLeft: '15px' }}
                      onClick={() => handleRowClick(arr.index)}
                    >
                      {clickedRows[arr.index] ? 'Zrobione' : 'W trakcie'}
                    </button>
                  </p>
                  <p>
                    {arr.item.holeOne && (
                      <>
                        <b>{arr.item.holeOne.name}</b>
                        {': '}
                        {arr.item.holeOne.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeTwo && arr.item.holeTwo.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeTwo.name}</b>
                        {': '}
                        {arr.item.holeTwo.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeThree && arr.item.holeThree.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeThree.name}</b>
                        {': '}
                        {arr.item.holeThree.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeFour && arr.item.holeFour.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeFour.name}</b>
                        {': '}
                        {arr.item.holeFour.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeFive && arr.item.holeFive.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeFive.name}</b>
                        {': '}
                        {arr.item.holeFive.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeSix && arr.item.holeSix.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeSix.name}</b>
                        {': '}
                        {arr.item.holeSix.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.lastHole && arr.item.lastHole.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.lastHole.name}</b>
                        {': '}
                        {arr.item.lastHole.size.value}
                        {' '}
                        mm
                      </>
                    )}
                  </p>
                  <hr />
                </>
              ))}
              {(type === 'palisadeHoles' || type === 'railHoles' || type === 'channelHoles' || type === 'rodHoles' || type === 'palisadeHolesAdd') && rightHoles.length > 0 && (
                <span><b>Prawe skrzydło</b></span>
              )}
              {Object.values(rightHoles).map((arr) => (
                <>
                  <p>
                    <b>{arr.productDef}</b>
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ backgroundColor: clickedRows[arr.index] ? 'green' : 'red', marginLeft: '15px' }}
                      onClick={() => handleRowClick(arr.index)}
                    >
                      {clickedRows[arr.index] ? 'Zrobione' : 'W trakcie'}
                    </button>
                  </p>
                  <p>
                    {arr.item.holeOne && (
                      <>
                        <b>{arr.item.holeOne.name}</b>
                        {': '}
                        {arr.item.holeOne.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeTwo && arr.item.holeTwo.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeTwo.name}</b>
                        {': '}
                        {arr.item.holeTwo.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeThree && arr.item.holeThree.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeThree.name}</b>
                        {': '}
                        {arr.item.holeThree.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeFour && arr.item.holeFour.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeFour.name}</b>
                        {': '}
                        {arr.item.holeFour.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeFive && arr.item.holeFive.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeFive.name}</b>
                        {': '}
                        {arr.item.holeFive.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeSix && arr.item.holeSix.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeSix.name}</b>
                        {': '}
                        {arr.item.holeSix.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.lastHole && arr.item.lastHole.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.lastHole.name}</b>
                        {': '}
                        {arr.item.lastHole.size.value}
                        {' '}
                        mm
                      </>
                    )}
                  </p>
                  <hr />
                </>
              ))}
              {Object.values(holes).map((arr) => (
                <>
                  <p>
                    <b>{arr.productDef}</b>
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ backgroundColor: clickedRows[arr.index] ? 'green' : 'red', marginLeft: '15px' }}
                      onClick={() => handleRowClick(arr.index)}
                    >
                      {clickedRows[arr.index] ? 'Zrobione' : 'W trakcie'}
                    </button>
                  </p>
                  <p>
                    {arr.item.holeOne && (
                      <>
                        <b>{arr.item.holeOne.name}</b>
                        {': '}
                        {arr.item.holeOne.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeTwo && arr.item.holeTwo.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeTwo.name}</b>
                        {': '}
                        {arr.item.holeTwo.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeThree && arr.item.holeThree.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeThree.name}</b>
                        {': '}
                        {arr.item.holeThree.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeFour && arr.item.holeFour.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeFour.name}</b>
                        {': '}
                        {arr.item.holeFour.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeFive && arr.item.holeFive.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeFive.name}</b>
                        {': '}
                        {arr.item.holeFive.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.holeSix && arr.item.holeSix.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.holeSix.name}</b>
                        {': '}
                        {arr.item.holeSix.size.value}
                        {' '}
                        mm
                      </>
                    )}
                    {arr.item.lastHole && arr.item.lastHole.name !== 'Nie dotyczy' && (
                      <>
                        {' '}
                        |
                        {' '}
                        <b>{arr.item.lastHole.name}</b>
                        {': '}
                        {arr.item.lastHole.size.value}
                        {' '}
                        mm
                      </>
                    )}
                  </p>
                  <hr />
                </>
              ))}
            </tr>
          </thead>
        </table>
      )}
    </>
  );
};

const Holes = styled.div`
  display: block;
  span {
    display: inline;
    margin-right: 10px;
  }
  span.bold {
    font-weight: 600;
    width: 80px;
  }
`;

const ButtonNav = styled.button`
  padding: 7px 5px;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
`;

const Paragraph = styled.p`
  font-size: 10px;
`;

const Info = styled.b`
  font-size: 10px;
`;

const Tab = styled.button`
  background: none;
  color: ${({ isClicked }) => (isClicked ? 'red' : 'white')};
  display: block;
  font-size: 18px;
  margin-top: 5px;
  margin-left: 5px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: color 0.3s ease;
  text-align: left;
  &:hover {
    color: red;
  };
  &.active {
    color: red;
  };
`;

const SubTab = styled.button`
  background-color: white;
  padding: 5px 5px;
  color: black; 
  margin-left: 5px;
  margin-top: 5px;
  border: 2px solid #f44336;
  font-size: 18px;
  cursor: pointer;
  float: left;
  transition: color 0.3s ease;
  transition-duration: 0.4s;
  &.active {
    font-weight: 600;
    background-color: #f44336;
    color: white;
  };
`;

const Collapsible = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const CollapsibleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d3d3d3;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
`;

const SectionTab = styled.button`
  width: 100%;
  background-color: gray;
  margin-right: 10px;
  margin-left: 5px;
  color: white;
  font-size: 12px;
  border: 1px solid gray;
  &.active {
    font-weight: 600;
    color: #fff;
    background-color: green;
    border: 1px solid green;
  }
`;

const ButtonAdd = styled.button`
  background: 0;
  border: 0;
  background-color: red;
  padding: 2px 6px;
  margin-bottom: 10px;
  margin-left: 20px;
  color: #fff;
  font-size: 16px;
  border: 1px solid #888;
  border-radius: 50%;
`;

const ButtonRemove = styled.button`
  background: 0;
  border: 0;
  background-color: red;
  padding: 2px 7px;
  margin-bottom: 10px;
  margin-left: 5px;
  color: #fff;
  font-size: 16px;
  border: 1px solid #888;
  border-radius: 50%;
`;

const Containerside = styled.div`
  display: flex;
  margin-top: 50px; /* Space for the fixed navbar */
`;

const Sidebar = styled.div`
  background-color: #484D4E;
  position: sticky;
  z-index: 1;
  padding: 10px;
  box-sizing: border-box;
  width: 400px;
  min-width: 400px;
  top: 50px;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: calc(100vh);
  max-height: calc(100vh);
`;

const Main = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Topsection = styled.div`
  background: #F2F2F2;
  flex-basis: 20%;
  padding: 10px;
  box-sizing: border-box;
`;

const Bottomsection = styled.div`
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  display: inline-block;
`;

const Navbar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: white;
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other content */
`;

export default ProductionMaterials;
