import React from 'react';
import PropTypes from 'prop-types';

const SpanResponse = ({ response, isPdf }) => {
  const xps = Object.keys(response.productionMaterials.calculation).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.calculation[key].name}</td>
      <td>{response.productionMaterials.calculation[key].material}</td>
      <td>
        {response.productionMaterials.calculation[key].amount.value}
        {' '}
        {response.productionMaterials.calculation[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.calculation[key].size.valueX}
        {' '}
        {response.productionMaterials.calculation[key].size.unit}
      </td>
      <td>
        {response.productionMaterials.calculation[key].size?.valueY}
      </td>
      {isPdf && (
      <>
        <td />
        <td className="tdCenter">
          <div className="pdfSquare" />
        </td>
      </>
      )}
    </tr>
  ));

  let xpsError = '';
  if ('error' in response.productionMaterials) {
    xpsError = `Wystąpiły błędy w projekcie: ${response.productionMaterials.error}`;
  }
  const xpsFullError = xpsError;

  return (
    <div>
      {isPdf && (
        <>
          <table className="table">
            <thead>
              <tr>
                <th colSpan="7">INFORMACJE OGÓLNE</th>
              </tr>
            </thead>
          </table>
          <div>
            <p>
              Szerokość pola [L]:
              {' '}
              {response.productionMaterials.areaWidth}
              {' '}
              mm
            </p>
          </div>
        </>
      )}
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>KAZIMIERZ XPS</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Szerokość płyty</th>
            <th className="responseThMeasure">Wysokość płyty</th>
            {isPdf && (
              <>
                <th className="responseThSaws">KAZIMIERZ XPS</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {xps}
        </tbody>
      </table>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <th>
          <th>
            {xpsFullError}
          </th>
        </th>
      </div>
    </div>
  );
};

SpanResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default SpanResponse;
